.accordionRoot {
  display: grid;

  width: 100%;
  max-width: var(--n-content-max-width);
  margin: 0 auto;
  padding: var(--n-size-5) 0;
  padding: 0 var(--n-size-3);

  & h3 {
    margin: 0;
  }

  & a {
    text-decoration: underline;
  }

  @media (width >= 1024px) {
    padding: 0 var(--n-size-10);
  }
}

.accordionItem__header {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: var(--n-size-1);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-width: 200px;
  padding: var(--n-size-2) 0;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-0);
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
  text-align: start;
  text-transform: uppercase;

  border: 0;
  border-bottom: 1px solid #e0e0e0;

  transition: border-bottom-color 0.2s ease;

  & svg {
    transition: transform 0.3s ease;
  }

  &[data-state='open'] {
    border-bottom-color: transparent;
  }

  &[data-state='open'] svg {
    transform: rotate(-180deg);
  }
}

.accordionItem__content {
  overflow: hidden;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.3s ease;

  &[data-state='open'] {
    border-bottom-color: #e0e0e0;
    animation: slideDown 0.3s ease forwards;
  }

  &[data-state='closed'] {
    animation: slideUp 0.3s ease forwards;
  }
}

@keyframes slideDown {
  from {
    height: 0;
    visibility: hidden;
  }

  to {
    height: var(--radix-accordion-content-height);
    visibility: visible;
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
    visibility: visible;
  }

  to {
    height: 0;
    visibility: hidden;
  }
}
