@import url('../../../../styles/customMediaQueries.css');

.root {
  position: relative;

  display: grid;
  gap: var(--n-size-4);

  width: 100%;
  padding-top: var(--n-size-5);
  padding-bottom: var(--n-size-5);

  @media (width >= 768px) {
    gap: var(--n-size-5);
    padding-top: var(--n-size-8);
    padding-bottom: var(--n-size-8);
  }
}

.title {
  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-4);
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.content {
  scrollbar-width: none; /* Firefox */
  scroll-snap-type: x mandatory;
  overflow-x: auto;

  -ms-overflow-style: none; /* IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

  & > * {
    scroll-snap-align: center;
  }
}

.content--with-padding {
  --min-padding: var(--n-size-2);
  --horizontal-padding: max(
    calc((100vw - var(--n-content-max-width)) / 2 + var(--min-padding)),
    var(--min-padding)
  );

  display: flex;
  gap: var(--n-size-3);

  width: 100%;
  max-width: 100%;
  padding-right: var(--horizontal-padding);
  padding-left: var(--horizontal-padding);

  @media (width >= 1024px) {
    --min-padding: var(--n-size-10);
  }
}

.content--end-to-end {
  display: flex;
}
