@import url('../../styles/customMediaQueries.css');

.wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
}

.sectionWrapper {
  width: 100%;
  margin-bottom: 10px;
}

.sectionTitle {
  width: 100%;

  /* Heading/H2 */
  font-family: var(--fontFamilySyne);
  font-size: 40px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
  text-align: center;
}

.sectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 5%;

  /* Body/Large/Regular */
  font-family: Montserrat;
  font-size: var(--n-font-size-1);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
  text-align: center;

  @media (width >= 768px) {
    padding: 0 10%;
  }

  @media (width >= 1128px) {
    padding: 0 20%;
  }
}

.sectionContent > span:nth-child(2) {
  padding-top: 30px;
}

.showCaseItem__sampleSales {
  aspect-ratio: 1 / 1;
  width: 75%;
  max-height: min(70vh, 700px);

  @media (width >= 550px) {
    aspect-ratio: 1 / 1;
  }

  @media (width >= 768px) {
    width: 33%;
  }
}

.showCaseItem__shopCircular {
  display: grid;
  gap: var(--n-size-2);
  place-content: center;

  width: 100%;
  height: 100%;
  padding: var(--n-size-5);

  font-family: Syne, sans-serif;
  line-height: 1;
  text-transform: uppercase;
}

.showCaseItem__collections {
  aspect-ratio: 1 / 1;
  width: 75%;
  max-width: 600px;

  @media (width >= 550px) {
    width: 60%;
  }

  @media (width >= 768px) {
    width: 45%;
  }

  @media (width >= 1024px) {
    width: 33.3333%;
  }
}

.showCaseItem__icons {
  composes: showCaseItem__collections;

  & * {
    color: white;
  }
}

.byFarCircular {
  display: grid;
  gap: var(--n-size-5);

  width: 100%;
  max-width: var(--n-content-max-width);
  margin: 0 auto;
  padding: 0 var(--n-size-3);

  & h1 {
    font-family: Syne, sans-serif;
    font-size: var(--n-font-size-4);
    font-weight: 700;
    text-transform: uppercase;

    @media (width >= 768px) {
      font-size: var(--n-font-size-6);
    }
  }

  & p {
    font-family: Montserrat, sans-serif;
    font-size: var(--n-font-size-1);

    @media (width >= 768px) {
      font-size: var(--n-font-size-2);
    }
  }

  @media (width >= 1024px) {
    padding: 0 var(--n-size-10);
  }
}
