@import url('../../../../styles/customMediaQueries.css');

.item {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }
}

.item__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item__content {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  display: grid;
  gap: var(--n-size-2);
  justify-items: start;

  padding: var(--n-size-5);

  color: var(--n-color-black);
  text-transform: uppercase;

  &,
  & h3 {
    font-family: Syne, sans-serif;
    font-size: var(--n-font-size-3);
    font-weight: 700;
    line-height: 1;
  }

  @media (width >= 768px) {
    font-size: var(--n-font-size-4);
  }
}
