@import url('../../../../styles/customMediaQueries.css');

.root {
  --section-bg-color: #f1f2f4;

  isolation: isolate;
  position: relative;

  overflow: hidden;
  display: grid;
  place-content: center;

  width: 100%;
  height: 680px;
  max-height: min(680px, 70vh);
  margin-bottom: var(--n-size-5);

  text-align: center;

  & > img {
    position: absolute;
    inset: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }
}

.container {
  z-index: 1;

  display: grid;
  gap: var(--n-size-2);
  place-items: center;

  width: 100%;
  max-width: var(--n-content-max-width);
  margin: auto;
  padding: var(--n-size-10) var(--n-size-2);

  & h1 {
    font-family: Syne, sans-serif;
    font-size: var(--n-font-size-5);
    font-weight: 700;
    line-height: 1;
    color: var(--n-color-white);
    text-transform: uppercase;

    @media (width >= 768px) {
      font-size: var(--n-font-size-7);
    }
  }

  & button {
    color: var(--n-color-white);
    background: transparent;
    border-color: var(--n-color-white);
  }

  @media (width >= 1024px) {
    padding: var(--n-size-10);
  }
}
