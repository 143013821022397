@import url('../../../../styles/customMediaQueries.css');

.root {
  display: grid;

  width: 100%;
  max-width: var(--n-content-max-width);
  margin: 0 auto;
  padding-top: var(--n-size-5);
  padding-bottom: var(--n-size-5);

  & a {
    text-decoration: none;
  }

  & > * {
    overflow: hidden;
  }

  @media (width >= 550px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (width >= 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: var(--n-size-8);
    padding-bottom: var(--n-size-8);
  }
}

.text {
  display: grid;
  gap: var(--n-size-4);

  & h2,
  p {
    color: var(--n-color-white);

    @media (width >= 768px) {
      grid-template-columns: 1fr 1fr 1fr;
      color: var(--n-color-black);
    }
  }

  & h2 {
    font-family: Syne, sans-serif;
    font-size: var(--n-font-size-2);
    font-weight: 700;
    text-transform: uppercase;

    @media (width >= 550px) {
      font-size: var(--n-font-size-3);
    }

    @media (width >= 1024px) {
      font-size: var(--n-font-size-4);
    }
  }

  & p {
    font-size: var(--n-font-size-2);
    line-height: 1.5;

    @media (width >= 768px) {
      font-size: var(--n-font-size-1);
    }
  }

  & button {
    justify-self: start;
  }

  @media (width >= 768px) {
    gap: var(--n-size-2);
  }

  @media (width >= 1024px) {
    gap: var(--n-size-4);
  }
}

.text__paragraphContainer {
  display: grid;
  gap: var(--n-size-2);

  @media (width >= 768px) {
    gap: var(--n-size-0);
  }

  @media (width >= 1024px) {
    gap: var(--n-size-2);
  }
}

.background {
  isolation: isolate;
  position: relative;
  padding: var(--n-size-5) var(--n-size-3);
  background: black;

  & img {
    position: absolute;
    inset: 0;

    width: 100%;
    height: 100%;

    opacity: 0.7;
    object-fit: cover;
  }

  & .text {
    position: relative;
    z-index: 1;
  }

  @media (width >= 768px) {
    aspect-ratio: 1 / 1;
    padding: var(--n-size-8);
    background: unset;

    & img {
      opacity: 1;
    }
  }
}

.content {
  overflow: hidden;
  display: none;
  grid-column: 2 / -1;
  align-content: center;

  padding: var(--n-size-8);

  @media (width >= 768px) {
    display: grid;
    aspect-ratio: 2 / 1;
  }
}

.shopCircular {
  display: grid;
  gap: var(--n-size-2);
  place-content: center;

  aspect-ratio: 1 / 1;
  width: 100%;
  height: 100%;
  padding: var(--n-size-5);

  font-family: Syne, sans-serif !important;
  line-height: 1;
  text-transform: uppercase;

  & h2 {
    font-family: Syne, sans-serif;
    font-weight: 700;
  }

  @media (width >= 550px) {
    aspect-ratio: unset;
  }

  @media (width >= 768px) {
    aspect-ratio: 1 / 1;
  }
}

.imageContainer {
  isolation: isolate;
  position: relative;
  display: grid;
  aspect-ratio: 1 / 1;

  & > img {
    position: absolute;
    z-index: -1;
    inset: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}

.cardText {
  & h2 {
    display: grid;
    align-content: end;

    width: 100%;
    height: 100%;
    padding: var(--n-size-5);

    font-family: Syne, sans-serif;
    font-size: var(--n-font-size-3);
    font-weight: 700;
    color: var(--n-color-white);
    text-transform: uppercase;
  }
}
